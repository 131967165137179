<template>
  <v-container class="grey lighten-5">
    <div>
      <iframe class="responsive-iframe" src="http://chat.appyaqui.com/#/chat/1"></iframe>
    </div>
    <!-- <v-row no-gutters>
      <v-col cols="6" sm="6">
        <v-card class="pa-2" outlined>
          <ul class="list-group" style="height: 500px">
            <li
              class="list-group-item d-flex justify-content-between align-items-center list-group-item-action"
              v-for="item in itemsUsers"
              :key="item.id"
              @click="showChatUser(item)"
            >
              {{ item.id + ' - ' + item.nombre }}
              <span v-bind:class="[{ withMessages: item.usuariototalnoleido > 0 }, 'badge', 'badge' - 'pill']">{{
                item.usuariototalnoleido
              }}</span>
            </li>
          </ul>
          <div class="col-md-8">
              <div class="col-12 col-sm-12 text-center text-sm-center">
                <h5 class="text-center">{{ 'Chat de: ' + nombreChat }}</h5>
              </div>
            </div> 
        </v-card>
      </v-col>
      <v-col cols="6" sm="6">
        <v-card class="pa-2" outlined tile>
          <basic-vue-chat
            :new-message="newMessage"
            @newOwnMessage="onNewOwnMessage"
            :initial-feed="feed"
            :title="'chat'"
          />
        </v-card>
      </v-col>
    </v-row> -->
  </v-container>
  <!-- 
  <div class="main-content-container container-fluid px-4">
    <div class="page-header row no-gutters py-4">
      <div class="col-12 col-sm-12 text-center text-sm-center mb-0">
        <h3 class="page-title text-center">Chat</h3>
      </div>
    </div>
    <div class="row py-3">
      <div class="col-md-4">
        <ul class="list-group">
          <li
            class="list-group-item d-flex justify-content-between align-items-center list-group-item-action"
            v-for="item in itemsUsers"
            :key="item.id"
            @click="showChatUser(item)"
          >
            {{ item.id + ' - ' + item.nombre }}
            <span v-bind:class="[{ withMessages: item.usuariototalnoleido > 0 }, badge, badge - pill]">{{
              item.usuariototalnoleido
            }}</span>
          </li>
        </ul>
      </div>
      <div class="col-md-8">
        <div class="col-12 col-sm-12 text-center text-sm-center">
          <h5 class="text-center">{{ 'Chat de: ' + nombreChat }}</h5>
        </div>
        <basic-vue-chat :new-message="newMessage" @newOwnMessage="onNewOwnMessage" :initial-feed="feed" />
      </div>
    </div> -->

  <!-- <d-list-group>
			<d-list-group-item class="p-3">
				<d-row>
					<d-col>
						<d-form>
							<d-form-row>
								<d-col md="3" class="form-group">
									<h5>usuarios</h5><br>
									<ul id="example-1">
										<li v-for="item in itemsUsers" :key="item.id" @click="showChatUser(item)">
											{{ item.nombres + '(' + item.usuariototalnoleido + ')' }}
										</li>
										</ul>
								</d-col>
								<d-col md="6" class="form-group">
								</d-col>
							</d-form-row>
						</d-form>
					</d-col>
				</d-row>
			</d-list-group-item>
		</d-list-group>-->
  <!-- </div> -->
</template>
<script>
import BasicVueChat from 'basic-vue-chat'
// import api from '../config/api'

export default {
  sockets: {
    connect: function () {
      console.log('conectado a cockets.')
    },
    newMessage: async function (data) {
      let usuario_idt = 1
      if (Number(usuario_idt) == Number(data.para)) {
        let listChat = JSON.parse(localStorage.getItem('listChat2')) || []
        console.log('listchat2', listChat)
        let rres = listChat.find(x => x == data.id)
        console.log('res bus', rres)
        if (listChat.indexOf(data.id) != -1) {
          console.log('ya me habían avisado...')
        } else {
          console.log('ok', data.id)
          listChat.push(data.id)
          localStorage.setItem('listChat2', JSON.stringify(listChat))
          this.newMessageFromClient(data)
          this.itemsUsers = await this.obtenerUsuarios()
        }
      }
    },

    // newMessage: async function(data) {
    // 	console.log("en espera notificaciones internas");
    // 	console.log(data);
    // 	let usuario_idt = 1; //localStorage.getItem("usuarioId");
    // 	console.log("usuario_id local");
    // 	console.log(usuario_idt);
    // 	console.log("usuario_id msg");
    // 	console.log(data.para);
    // 	if (Number(usuario_idt) == Number(data.para)) {
    // 		let lastIdChat = localStorage.getItem('lastIdChat02') || 0
    //         if (data.id == lastIdChat) {
    // 		  console.log("ya me habían avisado...")
    // 		} else {
    //   			localStorage.setItem('lastIdChat02', data.id)
    // 			this.newMessageFromClient(data);
    // 			this.itemsUsers = await this.obtenerUsuarios();
    // 		}
    // 	}
    // }
  },
  components: {
    BasicVueChat,
  },
  data() {
    return {
      items: [],
      newMessage: {},
      usuariochat: 0,
      fields: [
        { key: 'cuenta', sortable: true },
        { key: 'nombre', sortable: true },
        { key: 'saldo', sortable: false },
        { key: 'saldoC', sortable: false },
        { key: 'ver', sortable: false },
      ],
      itemsUsers: [],
      Movimientos: [],
      origenMsg: 0,
      feed: [],
      nombreChat: '',
    }
  },
  async mounted() {
    this.itemsUsers = await this.obtenerUsuarios()
  },
  methods: {
    async showChatUser(item) {
      try {
        this.nombreChat = item.nombre
        this.usuariochat = item.id
        console.log(item)
        let responseF = await this.$store.dispatch('post', { path: 'readChatByUser/' + item.id })

        //let responseF = await api.request('post', '/readChatByUser/' + item.id)
        console.log('READING CHAT---------------->')
        console.log(responseF)
        if (responseF.error) {
          console.log(responseF.error)
        } else {
          console.log('ok')
          this.itemsUsers = await this.obtenerUsuarios()
        }
        let response = await this.$store.dispatch('get', { path: 'chat/chatUser/' + item.id })

        // let response = await api.request('get', '/chatuser/' + item.id)
        if (response.error) {
          console.log('err--->', response.error)
          this.feed = []
        } else {
          //this.feed = []
          let result = response
          if (result.length > 0) {
            //la siguiente variable la uso para que al momento de llenar el chat con onNewOwnMessage en el mounted no envie notificaciones a la app
            this.origenMsg = 1
            this.feed.length = 0
            let data = null
            result.forEach(element => {
              if (element.usuario_id == 1) {
                data = {
                  id: 0,
                  author: 'Admin',
                  contents: element.mensaje,
                  date: this.setdate(element.fecha),
                }
              } else {
                data = {
                  author: 'Cliente-App',
                  imageUrl: '', //'https://admin.artdesignlabni.com/img/logo.5136ce2d.jpg',
                  contents: element.mensaje,
                  date: this.setdate(element.fecha),
                }
              }
              this.feed.push(data)
              console.log(this.feed)
            })

            this.origenMsg = 0
          } else {
            console.log('nel')
            this.feed = []
            this.origenMsg = 1
            this.feed.length = 0
            this.feed.push({ id: 0, author: '--', contents: 'sin mensajes', date: this.setdate(new Date()) })
            console.log('feed', this.feed)
          }
        }
      } catch (error) {
        console.log('error', error)
        this.feed = []
      }
    },
    async newMessageFromClient(data) {
      //alert('mensaje en newmessagefrom client')
      console.log('~~~~~~~~~~~~~~~~')
      console.log(data)
      let datamsg = {
        id: data.usuario_id,
        author: 'Cliente-App',
        imageUrl: ' ',
        image: ' ',
        contents: data.mensaje,
        date: this.setdate(new Date()),
      }
      //this.usuariochat = data.usuario_id
      this.newMessage = datamsg
    },
    pushToFeed() {
      console.log('aqui estoy en push to feed')
      console.log(element)
    },
    async onNewOwnMessage(message) {
      console.log('mi propio mensaje---------------->')
      //console.log(message)
      let newMessage = {
        para: this.usuariochat,
        usuario_id: 1,
        mensaje: message,
        fecha: this.$moment(new Date()).utc().format('YYYY-MM-DD hh:mm:ss'),
      }
      // if (this.origenMsg = 0){
      console.log(newMessage)
      let response = await this.$store.dispatch('post', { path: 'chat/', data: newMessage })
      // let response = await api.request('post', '/chat', newMessage)
      if (response.error) {
        console.log(response.error)
      } else {
        console.log('enviado')
      }
      //}
    },
    setdate(fecha) {
      let today = new Date(fecha)
      let dd = today.getDate()
      let mm = today.getMonth() + 1
      let yyyy = today.getFullYear()
      let hour = today.getHours()
      let min = today.getMinutes()
      if (dd < 10) {
        dd = '0' + dd
      }
      if (mm < 10) {
        mm = '0' + mm
      }
      if (hour < 10) {
        hour = '0' + hour
      }
      if (min < 10) {
        min = '0' + min
      }

      today = dd + '/' + mm + '/' + +yyyy + ' ' + hour + ':' + min
      //today = dd + '-' + mm + '-' + yyyy;
      console.log(today)
      return today
    },
    async obtenerUsuarios() {
      try {
        this.loading = true

        let res = await this.$store.dispatch('get', { path: 'clientes/getAll/' })
        this.loading = false
        //this.items = res
        //this.totalItems = this.items.length
        return res //this.itemsUsers
      } catch (error) {
        //this.items = []
        this.loading = false
        console.error('error', error)
        return []
      }

      // let response = await api.request('get', '/chatUsers')
      // if (response.error) {
      //   console.log(response.error)
      //   return response.error
      // } else {
      //   return response.data
      // }
    },
  },
}
</script>

<style lang="scss" scoped>
.container {
  position: relative;
  overflow: hidden;
  width: 100%;
  padding-top: 56.25%; /* 16:9 Aspect Ratio (divide 9 by 16 = 0.5625) */
}

/* Then style the iframe to fit in the container div with full height and width */
.responsive-iframe {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
}
</style>
